body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.stats-numbers {
  font-family: Tahoma, sans-serif;
  font-weight: 600;
  font-size: 1.15em;
}

.navbar {
  margin-bottom: 0;
}

.donate-button a {
  font-weight: bold;
  padding: 8px;
  background-color: #337ab7;
  color: white;
  border-radius: 4px;
}

.donate-button a:hover {
  color: lightgrey;
  text-decoration: none;
}
